<template>
  <span>
    <div v-show="isLoading" v-loading="isLoading" :element-loading-text="loadingText" class="fly-download" />
    <el-button :type="type" :size="size" :outtime="outtime" class="ele-btn-icon ml-20" style="margin-left: 0"
      icon="el-icon-download" :disabled="disabled" @click="done">{{ label }}</el-button>
  </span>
</template>

<script>
  import axios from "axios";
  import qs from 'qs';
  import Config from "@/config/setting.js"

  export default {
    name: 'Hamburger',
    props: {
      label: {
        type: String,
        default: '导出'
      },
      outtime: {
        type: [Number, null],
        default: 1000
      },
      url: {
        type: String,
        default: ''
      },
      params: {
        type: Object,
        default: null
      },
      size: {
        type: String,
        default: 'medium'
      },
      type: {
        type: String,
        default: 'primary'
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isLoading: false,
        loadingText: '加载中',
        fileName: '',
        searchData: {
          page: 0
        },
        totalPage: 0,
        jisu: 0,
      }
    },
    watch: {
      params: {
        immediate: true,
        handler(val) {
          this.searchData = Object.assign(this.searchData, val)
        }
      }
    },
    created() {
      this.searchData = Object.assign(this.searchData, this.$props.params)
    },
    methods: {
      sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
      },

      async makeData(page) {

        let postdata = {};
        postdata.page = page;
				console.log('this.searchData',this.searchData)
        postdata.search = JSON.stringify(this.searchData);
        postdata.file_name = this.fileName
        console.log('0-------where----------0000', postdata);
        let that = this;
        await this.$http.post(this.$props.url, postdata).then(res => {
          let resultdata = res.data;
          that.jisu++;
          that.sleep(that.$props.outtime).then(() => {
            console.log('0000000033000000000000000', resultdata)
            console.log('-------that-jisu', that.jisu)
            if (that.jisu > 0) {
              that.loadingText = '正在打包，' + (that.jisu) + '/' + that.totalPage
            }
            
            console.log('-------that-thatloadingText', that.loadingText)

            if ((that.jisu == that.totalPage) && (that.jisu > 0)) {
              const url = Config.baseURL + 'login/downLoadListExportFile?filename=' + that.fileName
              console.log('------6666-url', url)
              that.loadingText = '下载完毕'
              that.fileName = ''
              that.totalPage = 0;
              that.jisu = 0;
              that.isLoading = false
              window.location.href = url
            }
          })
        })
      },
      postFn() {
        // //console.log('099000', this.searchData);
        if (this.$props.url) {
          this.loadingText = '';
          let loop = 0;
          this.jisu = 0;

          this.$http.post(this.$props.url, {
            page: 0,
            search: JSON.stringify(this.searchData)
          }).then(res => {
            let timeOutVal = 0;
            console.log('xxxxxxxxxres.data.data',res.data.data)
            if (res.data.code === 0) {
              this.fileName = res.data.data.file_name;
              
              this.totalPage = res.data.data.total_page;
              timeOutVal = this.totalPage * this.$props.outtime;
              this.isLoading = true;
              for (let i = 1; i <= this.totalPage; i++) {
                this.makeData(i);
              }
              this.loadingText = '正在准备数据，请耐心等待'
            }

          })
        } else {
          this.isLoading = false
          this.$message.error('参数有误')
        }
      },
      done() {
        this.$emit('clickBefore')
        this.isLoading = true
        this.postFn()
      },
      clearWhere() {
        // //console.log('==========rewhere==============')
        this.searchData = {};

      }
    }
  }
</script>

<style scoped>
  .fly-download {
    position: fixed;
    z-index: 2000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

  }
</style>
